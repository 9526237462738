<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
      :style="style_centerblock"
      class="text-center"
    >
      <v-form
        ref="changeP"
        :style="style_form"
      >
        <v-card-title>
          You will change your password
        </v-card-title>

        <!-- Choix d'un password -->
        <v-text-field
          v-model.lazy="changePassword"
          :dark="this.$store.state.darkTheme ? true:false"
          :append-icon="openEye?'mdi-eye':'mdi-eye-off'"
          :rules="[rules.required, rules.min8characters, rules.strongPassword, rules.max40characters]"
          :type="openEye?'text':'password'"
          name="inputPassword"
          label="Enter your new password"
          hint="At least 8 characters with upper case, numeric character and special character"
          counter
          outlined
          class="mx-4"
          @click:append="(openEye = !openEye)"
        />

        <!-- Confirmation du password -->
        <v-text-field
          v-model.lazy="changePasswordConfirm"
          :dark="this.$store.state.darkTheme ? true:false"
          :append-icon="openEyeConfirm?'mdi-eye':'mdi-eye-off'"
          :rules="[rules.required, passCheckRules]"
          :type="openEyeConfirm?'text':'password'"
          name="confirmPassword"
          label="Confirm your new password"
          hint="At least 8 characters"
          counter
          outlined
          class="mx-4"
          @click:append="(openEyeConfirm = !openEyeConfirm)"
        />

        <!-- Boutons actions -->
        <v-card-actions class="my-4">
          <v-btn
            :dark="this.$store.state.darkTheme ? true:false"
            :color="red"
            text
            x-large
            @click="cancel"
          >
            Cancel
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            :dark="this.$store.state.darkTheme ? true:false"
            :disabled="!(changePassword && changePasswordConfirm)"
            color="green"
            x-large
            @click="validate"
          >
            Validate
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-row>

    <!-- Boite de dialogue resultat -->
    <v-dialog
      v-model="dialog"
      persistent
    >
      <v-card>
        <v-btn
          icon
          absolute
          top
          right
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Server answer
        </v-card-title>
        <v-alert
          v-if="validForm"
          :type="succesChangeP?'success':'error'"
          class="mx-4 my-4"
        >
          {{ reponse }}
        </v-alert>
        <v-card-text>
          <pre v-if="errorChangeP">
            {{ reponseServeur }}
          </pre>
        </v-card-text>
        <v-card-text v-if="succesChangeP">
          You will be redirect to the login page by closing this dialog box.
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- bar de chargement -->
    <v-dialog
      v-model="wait"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import config from '@/config'
  import axios from 'axios'

  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,

      // Init les v-model
      changePassword: '',
      changePasswordConfirm: '',

      // Init variable
      reponse: '',
      reponseServeur: '', // TODO : definir a vide
      succesChangeP: false,
      errorChangeP: false,
      validForm: false,
      openEye: false,
      openEyeConfirm: false,
      returnToLogin: false,
      dialog: false,
      wait: false,

      // Regles
      rules: {
        required: v => !!v || 'This field is required',
        min8characters: v => v.length >= 8 || 'Min 8 characters',
        strongPassword: v => (v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Password must contain an upper case letter, a numeric character, and a special character',
        max40characters: v => (v && v.length <= 40) || 'Your password must be less than 40 characters',
      },

    }),

    computed: {
      passCheckRules () {
        return () => (this.changePassword === this.changePasswordConfirm) || 'Password does not match'
      },
      style_centerblock () {
        return {
          height: `${this.$store.state.windowH - 200}px`,
          'background-color': 'transparent',
        }
      },
      style_bigbtn () {
        return {
          height: `${this.$store.state.windowH / 4}px`,
          width: `${this.$store.state.windowW / 4}px`,
          'min-width': '400px',
          'background-color': this.blue,
          color: 'white',
        }
      },
      style_form () {
        return {
          width: `${this.$store.state.windowW * (1 / 2)}px`,
          'background-color': 'transparent',
          margin: '10px 10px 10px 10px',
        }
      },
    },

    methods: {
      validate () {
        if (this.$refs.changeP.validate()) {
          this.validForm = true
          this.wait = true
          axios.post('/username/' + this.$store.state.account.login + '/set-password', {
            userPassword: this.changePassword,
          })
            .then(response => {
              this.reponseServeur = response.data
              this.wait = false
              if (this.reponseServeur.includes('SUCCESS')) {
                this.reponse = 'Your password has been change correctly.'
                this.succesChangeP = true
                this.errorChangeP = false
                this.returnToLogin = true
                this.dialog = true
              } else {
                this.reponse = 'There is something wrong.'
                this.errorChangeP = true
                this.succesChangeP = false
                this.returnToLogin = false
                this.dialog = true
              }
            })
        } else {
          this.validForm = false
        }
      },
      closeDialog () {
        this.dialog = false
        if (this.returnToLogin) {
          this.$router.push('/login')
        }
      },
      cancel () {
        // this.$refs.changeP.reset() // reset remet a null => probleme avec length
        this.changePassword = ''
        this.changePasswordConfirm = ''
        // this.$router.push('/')
      },
    },

  }
</script>

<style lang="scss">

</style>
